import React from "react";
import PropTypes from "prop-types";
import { FlexCol, FlexRow, Option, SelectField, Text } from "wm-ui-toolkit";
import "./CampaignSelector.scss";

export class CampaignSelector extends React.Component {
  static propTypes = {
    defaultCampaign: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
  };

  renderOptions = () => {
    const { options } = this.props;

    return options.map((option) => (
      <Option value={option.value} key={option.value}>
        <FlexRow gap="XXS" alignItems="center">
          {option.logo && <img src={option.logo} alt="" />}
          <Text type="H6">{option.label}</Text>
        </FlexRow>
      </Option>
    ));
  };

  renderSelectField = () => {
    const { defaultCampaign, onChange } = this.props;

    return (
      <SelectField
        onChange={onChange}
        requireSelect={true}
        defaultValue={defaultCampaign.id}
      >
        {this.renderOptions()}
      </SelectField>
    );
  };

  renderCampaign = () => {
    const { defaultCampaign } = this.props;
    const { attributes } = defaultCampaign;
    const { logoUrl, name } = attributes;

    return (
      <FlexRow
        className="CampaignSelector__campaign"
        gap="XXS"
        alignItems="center"
      >
        {logoUrl && (
          <img alt="" src={logoUrl} data-testid="OauthSetup__campaignLogo" />
        )}
        <Text type="H6">{name}</Text>
      </FlexRow>
    );
  };

  render() {
    const { label, options } = this.props;

    const multipleCampaigns = options.length > 1;

    return (
      <FlexCol gap="S" className="CampaignSelector">
        <Text type="H6">{label}</Text>
        {multipleCampaigns ? this.renderSelectField() : this.renderCampaign()}
      </FlexCol>
    );
  }
}
