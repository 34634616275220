import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tooltip } from "@wingmate/toolkit";
import { FlexRow, Text } from "wm-ui-toolkit";
import { AsteriskIcon, InfoTooltipIcon } from "wm-graphics";
import "./FieldLabel.scss";

export class FieldLabel extends React.Component {
  static defaultProps = {
    textType: "H6",
  };

  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    svg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    textType: PropTypes.string,
    textWeight: PropTypes.string,
    tooltipMessage: PropTypes.string,
  };

  renderSvg = (svg) => {
    switch (svg) {
      case "InfoTooltip":
        return <InfoTooltipIcon width={12} height={12} />;

      default:
        return (
          <AsteriskIcon
            className="FieldLabel__asterisk"
            data-testid="asterisk"
            width={8}
            height={8}
          />
        );
    }
  };

  renderSvgTooltip = () => {
    const { tooltipMessage } = this.props;
    return (
      <Tooltip title={tooltipMessage}>
        <sup>{this.renderSvg()}</sup>
      </Tooltip>
    );
  };

  render() {
    const { className, label, svg, textType, textWeight, tooltipMessage } =
      this.props;

    const fieldLabelClass = classNames("FieldLabel", className);

    return (
      <FlexRow
        className={fieldLabelClass}
        justifyContent="center"
        alignItems="center"
      >
        {
          <Text type={textType} weight={textWeight}>
            {label}
          </Text>
        }
        {svg &&
          (tooltipMessage ? (
            this.renderSvgTooltip(svg)
          ) : (
            <sup>{this.renderSvg(svg)}</sup>
          ))}
      </FlexRow>
    );
  }
}

export default FieldLabel;
