import { action, makeObservable, observable } from "mobx";
import * as usersService from "../requests/users";
import * as authenticationService from "../requests/authentication";
import { RootStore } from "./RootStore";

const STATUSES = [
  "fresh",
  "received_by_sales",
  "work_in_progress",
  "pending_decision",
  "contract_signed",
  "completed",
  "failed",
  "hibernated",
];

export class AuthStore {
  @observable bannerStore;

  @observable context;

  @observable isLoading;

  @observable isAuthenticated;

  @observable rootStore;

  constructor(bannerStore) {
    makeObservable(this);
    this.bannerStore = bannerStore;
    this.isLoading = true;
    this.isAuthenticated = true;
    this.context = {};
    this.rootStore = new RootStore(this);

    this.retrieveCurrentUserContext();
  }

  @action
  setContext = (context) => {
    context.statuses = STATUSES;

    this.context = context;
  };

  @action
  setIsAuthenticated = (isAuthenticated) => {
    this.isAuthenticated = isAuthenticated;
  };

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  retrieveEmailIfRemembered = async () => {
    const user = await usersService.getEmail();
    return user.email;
  };

  retrieveCurrentUserContext = async () => {
    try {
      const me = await usersService.getMe();

      me.statuses = STATUSES;

      this.setContext(me);
      this.setIsAuthenticated(true);
    } catch (err) {
      this.setIsAuthenticated(false);
    }

    this.setIsLoading(false);
  };

  resetPassword = async (userId) => {
    await authenticationService.resetPassword(userId);
  };

  changePassword = async (resetToken, password, passwordConfirmation) => {
    const { context } = await authenticationService.changePassword(
      resetToken,
      password,
      passwordConfirmation
    );

    this.setContext(context);
    this.setIsAuthenticated(true);
  };

  updateUserPassword = async (
    userId,
    currentPassword = "",
    password,
    passwordConfirmation,
    campaignId
  ) => {
    await authenticationService.updateUserPassword(
      userId,
      currentPassword,
      password,
      passwordConfirmation,
      campaignId
    );
  };

  logoutUser = async () => {
    try {
      await authenticationService.signOutUser();
    } catch (err) {
      console.log(err);
    } finally {
      this.setIsAuthenticated(false);
      this.setContext({});
      this.rootStore.clear();
    }
  };

  loginUser = async (payload) => {
    let successfulLogin = false;
    try {
      const me = await authenticationService.signInUser(payload);
      this.setContext(me);
      this.setIsAuthenticated(true);
      successfulLogin = true;
    } catch (err) {
      this.setContext({});
      this.setIsAuthenticated(false);
    }

    this.setIsLoading(false);
    return successfulLogin;
  };

  loginToOauth = async (payload) => {
    return await authenticationService.signInOauth(payload);
  };
}

export default AuthStore;
