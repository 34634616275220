import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@wingmate/toolkit";
import { Button, FlexCol, FlexRow, Spinner, Text } from "wm-ui-toolkit";
import { FieldLabel } from "../FieldLabel/FieldLabel";
import { FIELD_TYPES, InputField } from "../InputField/InputField";
import "./CreateForm.scss";

export class CreateForm extends React.Component {
  static defaultProps = {
    cancelLabel: "Cancel",
    submitLabel: "Submit",
    requiredGroups: [],
  };

  static propTypes = {
    afterSubmit: PropTypes.func,
    cancelLabel: PropTypes.string,
    customButtons: PropTypes.node,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        defaultValue: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.object,
          PropTypes.array,
        ]),
        disabled: PropTypes.bool,
        message: PropTypes.string,
        messageType: PropTypes.string,
        id: PropTypes.string,
        min: PropTypes.number,
        multiple: PropTypes.bool,
        options: PropTypes.oneOfType([
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              id: PropTypes.any,
            })
          ),
        ]),
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        type: PropTypes.oneOf(FIELD_TYPES),
      })
    ),
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    requiredGroups: PropTypes.arrayOf(PropTypes.array),
    submitDisabled: PropTypes.bool,
    submitLabel: PropTypes.string,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      submitDisabled: true,
      payload: this.defaultPayload(props),
    };
  }

  componentDidMount() {
    this.setSubmitDisabled();
  }

  defaultPayload(props) {
    const { fields } = props;

    const defaultPayload = {
      custom_fields: {},
    };

    fields.forEach((field) => {
      if (field.customField) {
        defaultPayload.custom_fields[field.id] = field.defaultValue;
      } else {
        defaultPayload[field.id] = field.defaultValue;
      }
    });

    return defaultPayload;
  }

  getMissingRequiredFields = () => {
    const { fields } = this.props;

    const requiredFields = fields.filter((field) => field.required);

    return requiredFields.filter((field) => !this.isFieldSet(field));
  };

  setSubmitDisabled = () => {
    const { requiredGroups } = this.props;

    const areAllRequiredFieldsSet = this.getMissingRequiredFields().length == 0;

    const areAllRequiredGroupsSet = requiredGroups.reduce(
      (result, group) => result && this.isGroupSet(group),
      true
    );

    if (areAllRequiredFieldsSet && areAllRequiredGroupsSet) {
      this.setState({ submitDisabled: false });
    } else {
      this.setState({ submitDisabled: true });
    }
  };

  isFieldSet = (field) => {
    const { payload } = this.state;

    if (field.customField) {
      return this.convertToBool(payload.custom_fields[field.id]);
    } else {
      return this.convertToBool(payload[field.id]);
    }
  };

  isGroupSet = (group) => {
    const result = group.reduce(
      (result, fieldId) => result || this.isFieldSet(this.getField(fieldId)),
      false
    );

    return result;
  };

  getField = (fieldId) => {
    const { fields } = this.props;

    return fields.find((field) => field.id === fieldId);
  };

  getErrors = () => {
    const { requiredGroups } = this.props;

    const groupErrors = [];

    requiredGroups.forEach((requiredGroup) => {
      if (!this.isGroupSet(requiredGroup)) {
        groupErrors.push(
          this.formatDependentRequiredFieldMessage(requiredGroup)
        );
      }
    });

    const missingRequiredFields = this.getMissingRequiredFields();

    const requiredFieldErrors = missingRequiredFields.map((field) => {
      return this.formatDependentRequiredFieldMessage([field.id]);
    });

    const allErrors = [...groupErrors, ...requiredFieldErrors];

    return allErrors;
  };

  formatDependentRequiredFieldMessage = (dependentFields) => {
    return dependentFields
      .map((fieldId, index) => {
        if (index === 0) {
          return `Must add ${this.getField(fieldId).name}`;
        } else {
          return `or ${this.getField(fieldId).name}`;
        }
      })
      .join(" ");
  };

  convertToBool(value) {
    if (value === 0) {
      return true;
    }

    if (Array.isArray(value) && value.length === 0) {
      return false;
    }

    return !!value;
  }

  onChange = (data, field) => {
    const { payload } = this.state;
    const { onChange } = this.props;

    if (field.customField) {
      payload.custom_fields[data.id] = data.value;
    } else {
      payload[data.id] = data.value;
    }

    this.setState({ payload });

    if (onChange) {
      onChange(payload);
    }

    this.setSubmitDisabled();
  };

  onSubmit = async () => {
    const { afterSubmit, onSubmit } = this.props;
    const { payload } = this.state;
    this.setState({ submitDisabled: true, isSubmitting: true });
    await onSubmit(payload);
    this.setState({ submitDisabled: false, isSubmitting: false });
    if (afterSubmit) {
      afterSubmit();
    }
  };

  renderTitle = () => {
    const { title } = this.props;

    return (
      title && (
        <Text type="H5" className="CreateForm__title">
          {title}
        </Text>
      )
    );
  };

  createInputFieldKey = (field) => {
    if (field.customField) {
      return `${field.id}-custom`;
    } else {
      return field.id;
    }
  };

  renderFields = () => {
    const { fields } = this.props;

    const visibleFields = fields.filter((field) => field.type !== "hidden");

    return visibleFields.map((field) => (
      <FlexCol className="fields__field" key={this.createInputFieldKey(field)}>
        {this.renderFieldLabel(field)}
        <InputField field={field} onChange={this.onChange} />
      </FlexCol>
    ));
  };

  renderFieldLabel = (field) => {
    if (field.required) {
      return (
        <FieldLabel
          label={field.name}
          svg={field.required}
          tooltipMessage="This field is required"
        />
      );
    }

    return <FieldLabel label={field.name} />;
  };

  renderCancelButton = () => {
    const { cancelLabel, onCancel } = this.props;

    return (
      onCancel && (
        <Button type="text" onClick={onCancel}>
          {cancelLabel}
        </Button>
      )
    );
  };

  renderSubmitButton = () => {
    const { onSubmit, submitLabel } = this.props;
    const { isSubmitting, submitDisabled } = this.state;

    const errors = this.getErrors();

    const disabled = isSubmitting || submitDisabled;

    return (
      onSubmit &&
      (errors.length > 0 ? (
        <Tooltip title={errors.join(". ")}>
          <div>
            <Button type="primary" onClick={this.onSubmit} disabled={disabled}>
              {submitLabel}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button type="primary" onClick={this.onSubmit} disabled={disabled}>
          {submitLabel}
        </Button>
      ))
    );
  };

  render() {
    const { customButtons } = this.props;
    const { isSubmitting } = this.state;

    return (
      <FlexCol
        className="CreateForm"
        alignItems="center"
        justifyContent="space-between"
      >
        {this.renderTitle()}
        <FlexCol className="CreateForm__fields" alignItems="center" gap="S">
          {this.renderFields()}
        </FlexCol>
        <FlexRow
          className="CreateForm__buttons"
          alignItems="center"
          justifyContent="space-between"
          gap="S"
        >
          <div>{customButtons}</div>
          <FlexRow gap="S">
            {isSubmitting ? <Spinner /> : this.renderCancelButton()}
            {this.renderSubmitButton()}
          </FlexRow>
        </FlexRow>
      </FlexCol>
    );
  }
}

export default CreateForm;
