import { endpoint } from "../../utils/endpoint";

import * as axiosService from "../axiosRequests";

const COMPANIES_LEADS_ENDPOINT = (companyId) =>
  endpoint(`app/v1.0/companies/${companyId}/leads.json`);

export async function getLeads(companyId, params = {}) {
  const response = await axiosService.axiosGet(
    COMPANIES_LEADS_ENDPOINT(companyId),
    params
  );

  return {
    data: response.data.data,
    meta: response.data.meta,
  };
}

export default {
  getLeads,
};
