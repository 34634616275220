import { axiosDelete, axiosGet } from "./axiosRequests";
import { endpoint } from "../utils/endpoint";

const ATTACHMENT_ENDPOINT = (id) => endpoint(`app/v1.0/attachments/${id}`);

export async function getAttachment(attachmentId, payload = {}) {
  const response = await axiosGet(ATTACHMENT_ENDPOINT(attachmentId), payload);

  return response.data;
}

export async function deleteAttachment(attachmentId) {
  const response = await axiosDelete(ATTACHMENT_ENDPOINT(attachmentId));

  return response.data;
}

export default { getAttachment };
