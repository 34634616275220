import * as axiosService from "../axiosRequests";

import { endpoint } from "../../utils/endpoint";

export const WEBHOOK_REQUESTS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/webhook_requests`);

export async function getWebhookRequests(campaignId, payload) {
  const response = await axiosService.axiosGet(
    WEBHOOK_REQUESTS_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}

export default {
  getWebhookRequests,
};
