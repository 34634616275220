import PropTypes from "prop-types";

export const leadBaseAttributesType = {
  address: PropTypes.string,
  amount: PropTypes.number,
  correspondences: PropTypes.shape({
    cold: PropTypes.number,
    email: PropTypes.number,
    inperson: PropTypes.number,
    phone: PropTypes.number,
    social: PropTypes.number,
  }),
  createdAt: PropTypes.string,
  creator: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.number,
    profileImageUrl: PropTypes.string,
    type: PropTypes.string,
  }),
  customFields: PropTypes.object,
  description: PropTypes.string,
  health: PropTypes.shape({
    value: PropTypes.string,
    tooltip: PropTypes.string,
  }),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  identifier: PropTypes.string,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  primaryPhotoUrl: PropTypes.string,
  status: PropTypes.string,
  updatedAt: PropTypes.string,
  owner: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    profileImageUrl: PropTypes.string,
    type: PropTypes.string,
  }),
};

export const leadBasePropTypes = PropTypes.shape({
  attributes: PropTypes.shape(leadBaseAttributesType),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
});
