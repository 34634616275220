import React from "react";
import PropTypes from "prop-types";
import { Text } from "wm-ui-toolkit";
import { Flyout } from "../../../../controls/Flyout/Flyout";
import { ReactComponent as ProfilePlaceholder } from "../../../../assets/images/grey-profile-placeholder.svg";
import "./MentionFlyout.scss";

export class MentionFlyout extends React.Component {
  static propTypes = {
    flyoutRef: PropTypes.object,
    showFlyout: PropTypes.bool,
    user: PropTypes.object,
  };

  render() {
    const { flyoutRef, showFlyout, user } = this.props;

    return (
      showFlyout && (
        <Flyout placementRef={flyoutRef}>
          <div className="MentionFlyout" data-testid="mention-flyout">
            {user.profileImageUrl ? (
              <img
                className="MentionFlyout__userPhoto"
                src={user.profileImageUrl}
                alt={" "}
              />
            ) : (
              <ProfilePlaceholder
                data-testid="placeholder-image"
                width="80px"
                height="80px"
              />
            )}
            <Text>{user.fullName}</Text>
            <Text>{user.type}</Text>
          </div>
        </Flyout>
      )
    );
  }
}

export default MentionFlyout;
