/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  // returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 *
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */

import moment from "moment";

export function dateAdd(date, interval, units) {
  if (!(date instanceof Date)) return undefined;
  let ret = new Date(date); // Don't change original date
  const checkRollover = function () {
    if (ret.getDate() !== date.getDate()) ret.setDate(0);
  };
  switch (String(interval).toLowerCase()) {
    case "year":
      ret.setFullYear(ret.getFullYear() + units);
      checkRollover();
      break;
    case "quarter":
      ret.setMonth(ret.getMonth() + 3 * units);
      checkRollover();
      break;
    case "month":
      ret.setMonth(ret.getMonth() + units);
      checkRollover();
      break;
    case "week":
      ret.setDate(ret.getDate() + 7 * units);
      break;
    case "day":
      ret.setDate(ret.getDate() + units);
      break;
    case "hour":
      ret.setTime(ret.getTime() + units * 3600000);
      break;
    case "minute":
      ret.setTime(ret.getTime() + units * 60000);
      break;
    case "second":
      ret.setTime(ret.getTime() + units * 1000);
      break;
    default:
      ret = undefined;
      break;
  }
  return ret;
}

export function formatDateByYear(date) {
  let formattedDate;
  if (moment(date).year() === moment().year()) {
    formattedDate = moment(date).format("MMM D");
  } else {
    formattedDate = moment(date).format("MMM D YYYY");
  }
  return formattedDate;
}

export function formatDateByMonthDayYear(date) {
  return moment(date).format("MMM D, YYYY");
}

export function formatDateWithTime(date) {
  return moment(date).format("MMM D YYYY, h:mm a");
}

export function formatDateLongWithTime(date) {
  const formattedDate = moment(date).format("MMMM D, YYYY");
  const formattedTime = moment(date).format("h:mm a");
  return formattedDate.concat(" at ").concat(formattedTime);
}

export function formatMonthDayTime(date) {
  return moment(date).format("MMM DD, h:mm a");
}

export function getHours(time) {
  const hours = parseInt(time.split(":")[0], 10);
  return hours;
}

export function getMinutes(time) {
  const minutes = parseInt(time.split(":")[1], 10);
  return minutes;
}

export function combineDateAndTime(date, time) {
  let newDate = moment(date);
  if (time) {
    newDate = newDate.set({ hour: getHours(time), minute: getMinutes(time) });
  }
  newDate = new Date(newDate);
  return newDate;
}

export function getShortDurationFromDate(date) {
  const today = moment();
  const inputDate = moment(date);
  const duration = moment.duration(today.diff(inputDate));

  if (duration.asHours() < 12) {
    return inputDate.format("h:mm A");
  } else if (duration.asHours() > 12 && duration.asHours() < 24) {
    return Math.floor(duration.asHours()) + "h";
  } else if (duration.asDays() < 7) {
    return Math.floor(duration.asDays()) + "d";
  } else if (duration.asWeeks() < 5) {
    return Math.floor(duration.asWeeks()) + "w";
  } else if (duration.asMonths() < 12) {
    return Math.floor(duration.asMonths()) + "mo";
  } else {
    return Math.floor(duration.asYears()) + "y";
  }
}

export function now() {
  return moment().format();
}

export function isWithinDateRange(date, startDate, endDate) {
  if (!startDate && !endDate) {
    return true;
  }

  if (!startDate) {
    return moment(date).isBefore(endDate, null, "[]");
  }

  if (!endDate) {
    return moment(date).isAfter(startDate, null, "[]");
  }

  return moment(date).isBetween(startDate, endDate, null, "[]");
}

export function getCurrentMonth() {
  return moment().format("MMMM");
}
