import * as axiosService from "../axiosRequests";

import { endpoint } from "../../utils/endpoint";

export const WEBHOOK_ENDPOINTS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/webhook_endpoints`);

export const WEBHOOK_ENDPOINT_ENDPOINT = (campaignId, webhookEndpointId) =>
  endpoint(
    `app/v1.0/campaigns/${campaignId}/webhook_endpoints/${webhookEndpointId}`
  );

export async function getWebhookEndpoints(campaignId) {
  const response = await axiosService.axiosGet(
    WEBHOOK_ENDPOINTS_ENDPOINT(campaignId)
  );

  return response.data.data;
}

export async function postWebhookEndpoint(campaignId, payload) {
  const response = await axiosService.axiosPost(
    WEBHOOK_ENDPOINTS_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}

export async function deleteWebhookEndpoint(campaignId, webhookEndpointId) {
  const response = await axiosService.axiosDelete(
    WEBHOOK_ENDPOINT_ENDPOINT(campaignId, webhookEndpointId)
  );

  return response.data.data;
}

export default {
  getWebhookEndpoints,
};
