import { action, computed, makeObservable, observable } from "mobx";
import * as leadLinksService from "../requests/leadLinks/leadLinks";
import * as companiesLeadsService from "../requests/companies/leads";

const PARENT_LINK_TYPE = "parent";

export class LeadLinksStore {
  leadDetailsStore;

  @observable leads = [];

  @observable leadLinks = [];

  @observable isLoading = true;

  constructor(leadDetailsStore) {
    makeObservable(this);
    this.leadDetailsStore = leadDetailsStore;
  }

  @computed get childLeads() {
    const childLinks = this.filterLinks("parentLead");

    return childLinks.map((link) => link.attributes.childLead);
  }

  @computed get parentLeads() {
    const parentLinks = this.filterLinks("childLead");

    return parentLinks.map((link) => link.attributes.parentLead);
  }

  @action
  setLeads(leads) {
    this.leads = leads;
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setLeadLinks(leadLinks) {
    this.leadLinks = leadLinks;
  }

  @action
  deleteLeadLink(linkId) {
    const leadLinkIndex = this.leadLinks.findIndex(
      (leadLink) => leadLink.id === linkId
    );

    if (leadLinkIndex !== -1) {
      this.leadLinks.splice(leadLinkIndex, 1);
    }
  }

  getLinkId(outwardLeadId) {
    const matchingLink = this.leadLinks.find((link) => {
      if (link.attributes.childLead.id === outwardLeadId) {
        return link;
      }
      if (link.attributes.parentLead.id === outwardLeadId) {
        return link;
      }
      return null;
    });
    return matchingLink.id;
  }

  filterLinks(linkType) {
    const { leadId } = this.leadDetailsStore;

    return this.leadLinks.filter((leadLink) => {
      if (leadLink.attributes[linkType].id === parseInt(leadId, 10)) {
        return true;
      } else {
        return false;
      }
    });
  }

  @action
  async addLeadLinkAsync(linkType, outwardLeadId) {
    const { leadId } = this.leadDetailsStore;

    let parentLeadId;
    let childLeadId;

    if (linkType === PARENT_LINK_TYPE) {
      parentLeadId = leadId;
      childLeadId = outwardLeadId;
    } else {
      parentLeadId = outwardLeadId;
      childLeadId = leadId;
    }

    const payload = {
      lead_link: {
        parent_lead_id: parentLeadId,
        child_lead_id: childLeadId,
      },
    };

    const response = await leadLinksService.postLeadLink(payload);
    this.leadLinks.push(response);
  }

  async deleteLeadLinkAsync(outwardLeadId) {
    const linkId = this.getLinkId(outwardLeadId);

    this.deleteLeadLink(linkId);
    await leadLinksService.deleteLeadLink(linkId);
  }

  @action
  async retrieveLeadsAsync(filters = {}) {
    const { campaign } = this.leadDetailsStore.rootStore.context;
    const { companyId } = campaign;

    this.setIsLoading(true);
    try {
      const response = await companiesLeadsService.getLeads(companyId, filters);

      const { data } = response;

      this.setLeads(data);
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }

  async retrieveLinksForLeadAsync() {
    const { leadId } = this.leadDetailsStore;
    this.setIsLoading(true);
    const response = await leadLinksService.getLeadLinks(leadId);
    this.setLeadLinks(response);
    this.setIsLoading(false);
  }
}

export default LeadLinksStore;
