import React from "react";
import { PropTypes } from "prop-types";
import { inject, observer } from "mobx-react";
import { Drawer, Text } from "@wingmate/toolkit";
import { AppContext } from "../../context/AppProvider";
import { AddLeadSlideoutButton } from "./AddLeadSlideoutButton/AddLeadSlideoutButton";
import { rootStorePropTypes } from "../../proptypes/stores";
import { withRouter } from "../../utils/withRouter";
import { withTranslations } from "../../utils/withTranslations";
import { AddLeadForm } from "./AddLeadForm";

import "./AddLeadSlideout.scss";

@withTranslations
@withRouter
@inject("rootStore")
@observer
export class AddLeadSlideout extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    navigate: PropTypes.func,
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  componentWillUnmount() {
    const { rootStore } = this.props;

    rootStore.setLeadSlideoutCampaignVisibility(false);
  }

  onAddLeadButtonClick = () => {
    const { rootStore } = this.props;
    rootStore.setAddLeadSlideoutOpen(true);
  };

  closeSlideout = () => {
    const { rootStore } = this.props;

    rootStore.setAddLeadSlideoutOpen(false);
    rootStore.resetDefaultLeadSlideoutProps();
  };

  render() {
    const { campaign } = this.context;
    const { leadLabel } = campaign;
    const { rootStore, t } = this.props;
    const { addLeadSlideoutOpen } = rootStore;

    return (
      <>
        <Drawer
          className="AddLeadSlideout"
          open={addLeadSlideoutOpen}
          onClose={this.closeSlideout}
          getContainer={document.getElementById("pageLayer")}
          rootStyle={{ position: "absolute" }}
          maskClosable={false}
          title={(
            <Text className="AddLeadSlideout__Title" type="H5">
              {t("common:layouts.slideout.add", { leadLabel })}
            </Text>
          )}
        >
          {addLeadSlideoutOpen && <AddLeadForm onClose={this.closeSlideout} />}
        </Drawer>
        <AddLeadSlideoutButton
          onClick={this.onAddLeadButtonClick}
          visible={!addLeadSlideoutOpen}
        />
      </>
    );
  }
}

export default AddLeadSlideout;
