import { action, makeObservable, observable } from "mobx";

import * as leadAttachmentsService from "../requests/leads/attachments";
import * as attachmentsService from "../requests/attachments";

export class AttachmentsStore {
  leadDetailsStore;

  @observable attachments = [];

  @observable isLoading = false;

  constructor(leadDetailsStore) {
    makeObservable(this);
    this.leadDetailsStore = leadDetailsStore;
  }

  @action
  setAttachments(attachments) {
    this.attachments = attachments;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  addAttachment(attachment) {
    this.attachments.push(attachment);
  }

  @action
  async getAttachmentsAsync() {
    this.setIsLoading(true);
    try {
      const response = await leadAttachmentsService.getAttachments(
        this.leadDetailsStore.leadId
      );
      this.setAttachments(response.data);
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }

  @action
  async deleteAttachmentAsync(attachmentId) {
    await attachmentsService.deleteAttachment(attachmentId);

    const attachmentToDeleteIndex = this.attachments.findIndex(
      (attachment) => attachment.attributes.id === attachmentId
    );

    if (attachmentToDeleteIndex !== -1) {
      this.deleteAttachment(attachmentToDeleteIndex);
    }
  }

  @action
  deleteAttachment(attachmentToDeleteIndex) {
    this.attachments.splice(attachmentToDeleteIndex, 1);
  }
}

export default AttachmentsStore;
