import React, { Suspense as ReactSuspense } from "react";
import { Spinner } from "wm-ui-toolkit";
import PropTypes from "prop-types";
import "./Suspense.scss";

export class Suspense extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;
    return (
      <ReactSuspense
        fallback={
          <div className="Suspense">
            <Spinner />
          </div>
        }
      >
        {children}
      </ReactSuspense>
    );
  }
}

export default Suspense;
