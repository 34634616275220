import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Search, Text } from "@wingmate/toolkit";
import {
  authStorePropTypes,
  rootStorePropTypes,
} from "../../../proptypes/stores";
import { AppContext } from "../../../context";
import { withRouter } from "../../../utils/withRouter";

import "./ContractSelector.scss";

@withRouter
@inject("authStore", "rootStore")
@observer
export class ContractSelector extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    authStore: authStorePropTypes,
    contracts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        campaign: PropTypes.object,
        active: PropTypes.bool,
      })
    ),
    defaultContract: PropTypes.shape({
      id: PropTypes.string,
      active: PropTypes.bool,
      campaign: PropTypes.object,
    }),
    navigate: PropTypes.func,
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  onSelectedChange = async (menuPayload) => {
    const { rootStore } = this.props;
    const { contractStore } = rootStore;

    const { key: contractId } = menuPayload;

    const selectedContract = contractStore.getActiveContract();

    const payload = { contract: { active: true } };

    if (contractId !== selectedContract.id) {
      await contractStore.updateContract(contractId, payload);

      window.location.replace("/");
    }
  };

  renderContractItems = () => {
    const { contracts } = this.props;

    return contracts.map((contract) => {
      const { attributes } = contract;
      const { campaign } = attributes;
      const { attributes: campaignAttributes } = campaign;
      const { logoUrl, name } = campaignAttributes;

      return {
        key: contract.id,
        label: <Text type="P">{name}</Text>,
        title: name,
        icon: <div className="dropdown__logo">{logoUrl && <img src={logoUrl} alt="" />}</div>,
      }
    });
  }

  render() {
    const { contracts, rootStore } = this.props;
    const { contractStore } = rootStore;

    let selectedContract = contractStore.getActiveContract();

    let name;
    if (selectedContract) {
      const { attributes } = selectedContract;
      const { campaign } = attributes;
      name = campaign.attributes.name;
    }

    const showDropdown = contracts.length > 1;

    return (
      <div className="ContractSelector" data-testid="ContractSelector">
        {selectedContract && (
          <>
            {showDropdown ? (
              <Search
                rootClassName="ContractSelector__dropdown"
                items={this.renderContractItems()}
                onClick={this.onSelectedChange}
                id="contractSelector"
                placeholder={name}
                size="small"
              />
            ) : (
              <Text className="ContractSelector__selected" type="H6">
                {name}
              </Text>
            )}
          </>
        )}
      </div>
    );
  }
}

export default ContractSelector;
