import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { AppContext } from "../../../context";
import { withRouter } from "../../../utils/withRouter";
import { FlexCol, FlexRow, Spinner, Text } from "wm-ui-toolkit";
import { Section } from "../../../layouts/Section";
import { ReactComponent as WingmateLogo } from "../../../assets/images/wingmate-dark-icon.svg";
import { withTranslations } from "../../../utils/withTranslations";
import { ConnectArrowsIcon } from "wm-graphics";
import { rootStorePropTypes } from "../../../proptypes/stores";
import { CampaignSelector } from "./CampaignSelector/CampaignSelector";
import { Error } from "../Error/Error";
import { parseErrorMessage } from "../../../utils/errors";
import { LoadButton } from "../../../controls/LoadButton/LoadButton";
import * as campaignsAppInstallsService from "../../../requests/oauth/appInstalls";
import * as appsService from "../../../requests/oauth/apps";
import "./OauthSetup.scss";

@withTranslations
@withRouter
@inject("rootStore")
@observer
class OauthSetup extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    appId: PropTypes.string,
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      app: null,
      selectedCampaignId: null,
      isConnecting: false,
      errorMessage: null,
      userMessage: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    const { currentUser } = this.context;
    if (currentUser && currentUser.hasAdminPrivileges) {
      await this.retrieveContracts();

      await this.getApp();
    } else {
      this.setState({ errorMessage: t("error.forbidden") });
    }

    this.setState({ isLoading: false });
  }

  getApp = async () => {
    const { appId, t } = this.props;
    try {
      const app = await appsService.getApp(appId);

      this.setState({ app });
    } catch (err) {
      if (err.response && err.response.status === 404) {
        this.setState({
          errorMessage: t("error.notSupported"),
        });
      } else {
        this.setState({
          errorMessage: t("error.default"),
        });
      }
    }
  };

  retrieveContracts = async () => {
    const { rootStore, t } = this.props;
    const { contractStore } = rootStore;
    const { currentUser } = this.context;

    try {
      await contractStore.getUserContractsAsync(currentUser.id);
      this.setInitialCampaignId();
      return true;
    } catch {
      this.setState({ userMessage: t("error.failedToLoadContracts") });
      return false;
    }
  };

  setInitialCampaignId = () => {
    const { rootStore } = this.props;
    const { contractStore } = rootStore;

    const activeContract = contractStore.getActiveContract();
    const { campaign } = activeContract.attributes;
    const { id } = campaign;

    this.setState({ selectedCampaignId: id });
  };

  onChange = (campaignId) => {
    const selectedCampaignId = campaignId;

    this.setState({ selectedCampaignId, userMessage: null });
  };

  onConnect = async () => {
    this.setState({ isConnecting: true, userMessage: null });
    const data = await this.createAppInstall();

    if (data) {
      window.location.href = data.authorizationUrl;
    }

    this.setState({ isConnecting: false });
  };

  createAppInstall = async () => {
    const { appId, t } = this.props;
    const { selectedCampaignId } = this.state;
    const { app } = this.state;

    const payload = {
      app_id: appId,
      app_name: app.name,
    };

    try {
      return await campaignsAppInstallsService.postAppInstall(
        selectedCampaignId,
        payload
      );
    } catch (err) {
      let userMessage;
      if (err.response && err.response.status === 400) {
        userMessage = parseErrorMessage(err, t("error.default"));
      } else {
        userMessage = t("error.default");
      }

      this.setState({ userMessage });
      return null;
    }
  };

  renderLogos = () => {
    const { app } = this.state;

    return (
      <FlexRow alignItems="center" gap="XS">
        <WingmateLogo width={80} height={60} />
        <ConnectArrowsIcon height={50} width={50} />
        <img
          src={app.imageUrl}
          alt=""
          data-testid="OauthSetup__appLogo"
          width={80}
          height={60}
        />
      </FlexRow>
    );
  };

  renderTitle = () => {
    const { t } = this.props;
    const { app } = this.state;

    return (
      <FlexCol gap="L" alignItems="center">
        {this.renderLogos()}
        <Text type="H4">{t("title", { appName: app.name })}</Text>
      </FlexCol>
    );
  };

  renderForm = () => {
    const { app, isConnecting, isLoading, userMessage } = this.state;
    const { rootStore, t } = this.props;
    const { contractStore } = rootStore;
    const { contracts } = contractStore;
    const activeContract = contractStore.getActiveContract();

    const campaignOptions = contracts.map((contract) => ({
      value: contract.attributes.campaign.id,
      logo: contract.attributes.campaign.attributes.logoUrl,
      label: contract.attributes.campaign.attributes.name,
    }));

    return !isLoading ? (
      <FlexCol gap="M" alignItems="center" justifyContent="space-between">
        {this.renderTitle()}
        {activeContract && (
          <CampaignSelector
            defaultCampaign={activeContract.attributes.campaign}
            options={campaignOptions}
            label={t("description", { appName: app.name })}
            onChange={this.onChange}
          />
        )}
        <Text className="OauthSetup__userMessage">{userMessage}</Text>
        <LoadButton
          type="primary"
          isLoading={isConnecting}
          onClick={this.onConnect}
          disabled={!activeContract}
        >
          {t("connect")}
        </LoadButton>
      </FlexCol>
    ) : (
      <Spinner />
    );
  };

  render() {
    const { errorMessage } = this.state;

    return (
      <FlexCol
        className="OauthSetup"
        alignItems="center"
        justifyContent="center"
      >
        <Section>
          {errorMessage ? (
            <Error errorMessage={errorMessage} />
          ) : (
            this.renderForm()
          )}
        </Section>
      </FlexCol>
    );
  }
}

export { OauthSetup };
