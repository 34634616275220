import { action, computed, makeObservable, observable } from "mobx";
import { ApprovalConditionsStore } from "./ApprovalConditionsStore";
import { CampaignVariablesStore } from "./CampaignVariablesStore";
import { PriceSheetsStore } from "./PriceSheetsStore";
import { PurchasablesStore } from "./PurchasablesStore";
import { QuoteTemplatesStore } from "./QuoteTemplatesStore";
import { TargetsStore } from "./TargetsStore";
import { TasksStore } from "./TasksStore";
import { UsersStore } from "./UsersStore";
import { LeadFormsStore } from "./LeadFormsStore";
import { CompanyTransactionsStore } from "./CompanyTransactionsStore";
import { WebhooksStore } from "./WebhooksStore";
import { WebhookRequestsStore } from "./WebhookRequestsStore";
import * as campaignsService from "../requests/campaigns";
import * as apiTokenService from "../requests/campaigns/apiToken";

export class AdminStore {
  @observable attachments = [];

  @observable campaign = {};

  @observable isLoading = false;

  @observable isSaving = false;

  @observable isInitializing = true;

  @observable showBreadcrumbs = true;

  @observable campaignId = true;

  constructor(campaignId, rootStore) {
    makeObservable(this);

    this.campaignId = campaignId;
    this.rootStore = rootStore;
    this.initializeCampaign(campaignId);

    this.approvalConditionsStore = new ApprovalConditionsStore(this);
    this.purchasablesStore = new PurchasablesStore(this);
    this.quoteTemplatesStore = new QuoteTemplatesStore(this);
    this.usersStore = new UsersStore(this);
    this.tasksStore = new TasksStore(this);
    this.targetsStore = new TargetsStore(this);
    this.campaignVariablesStore = new CampaignVariablesStore(this);
    this.leadFormsStore = new LeadFormsStore(this);
    this.priceSheetsStore = new PriceSheetsStore(this);
    this.companyTransactionsStore = new CompanyTransactionsStore(this);
    this.webhooksStore = new WebhooksStore(this);
    this.webhookRequestsStore = new WebhookRequestsStore(this);
  }

  async initializeCampaign(campaignId) {
    this.setIsInitializing(true);
    await this.getCampaignAsync(campaignId);
    this.setIsInitializing(false);
  }

  @computed get bannerStore() {
    return this.rootStore.bannerStore;
  }

  @action
  setCampaign(campaign) {
    this.campaign = campaign;
  }

  @action
  setShowBreadcrumbs(showBreadcrumbs) {
    this.showBreadcrumbs = showBreadcrumbs;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setIsSaving(isSaving) {
    this.isSaving = isSaving;
  }

  @action
  setIsInitializing(isInitializing) {
    this.isInitializing = isInitializing;
  }

  @action
  async getCampaignAsync(campaignId) {
    this.setIsLoading(true);

    const campaign = await campaignsService.getCampaign(campaignId);

    this.setCampaign(campaign);
    this.setIsLoading(false);
  }

  @action
  async getLogoUploadUrl(campaignId, filename) {
    const response = await campaignsService.getLogoUploadUrl(
      campaignId,
      filename
    );

    return response;
  }

  @action
  async updateCampaignAsync(payload) {
    this.setIsLoading(true);

    const campaign = await campaignsService.updateCampaign(
      this.campaignId,
      payload
    );

    await this.rootStore.authStore.retrieveCurrentUserContext();
    this.setCampaign(campaign);
    this.setIsLoading(false);
  }

  async getCampaignApiTokenAsync() {
    const response = await apiTokenService.getCampaignApiToken(this.campaignId);
    return response;
  }

  async createCampaignApiTokenAsync() {
    const response = await apiTokenService.postCampaignApiToken(
      this.campaignId
    );
    return response;
  }

  async deleteCampaignApiTokenAsync() {
    const response = await apiTokenService.deleteCampaignApiToken(
      this.campaignId
    );
    return response;
  }
}

export default AdminStore;
