import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@wingmate/toolkit";
import { Text } from "@wingmate/toolkit";
import { withTranslations } from "../../../utils/withTranslations";
import { ReactComponent as ProfilePlaceholder } from "../../../assets/images/grey-profile-placeholder.svg";
import profilePlaceHolder from "../../../assets/images/user-profile-placeholder.svg";
import "./Username.scss";

@withTranslations
export class Username extends React.Component {
  static defaultProps = {
    textType: "H6",
    height: "24px",
    hideName: false,
    hideImage: false,
    showTooltip: true,
    width: "24px",
  };

  static propTypes = {
    height: PropTypes.string,
    hideImage: PropTypes.bool,
    hideName: PropTypes.bool,
    showTooltip: PropTypes.bool,
    t: PropTypes.func,
    textType: PropTypes.oneOf([
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      "S",
      "P",
      "T",
    ]),
    toolTipMessage: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      fullName: PropTypes.string,
      profileImageUrl: PropTypes.string,
    }),
    width: PropTypes.string,
  };

  getInitials(fullName) {
    const nameParts = fullName
      .trim()
      .replace(/ *\([^)]*\) */g, "")
      .split(" ");

    const firstName = nameParts[0];
    if (nameParts.length >= 2) {
      const lastName = nameParts[nameParts.length - 1];
      return firstName[0] + lastName[0];
    } else {
      return firstName[0];
    }
  }

  getTooltipTipMessage = (user) => {
    const { toolTipMessage } = this.props;

    if (!toolTipMessage) {
      if (user) {
        return user.fullName;
      }
      return null;
    }
    return toolTipMessage;
  };

  getUsernameText = () => {
    const { t, user } = this.props;

    if (user) {
      return user.fullName;
    }
    return t("common:user.deletedUser");
  };

  getSizeProps = () => {
    const { height, width } = this.props;

    return { width, height };
  }

  renderMissingProfileImage = () => {
    return <ProfilePlaceholder style={{ ...this.getSizeProps() }} />;
  };

  renderUserPhoto = (user) => {
    if (user.profileImageUrl) {
      return (
        <img
          src={user.profileImageUrl}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = profilePlaceHolder;
          }}
          style={{ ...this.getSizeProps() }}
        />
      );
    } else {
      return (
        <div className="Username__initials" style={{ ...this.getSizeProps() }}>
          <Text type="P">{this.getInitials(user.fullName)}</Text>
        </div>
      );
    }
  };

  renderUserProfileImage = (user) => {
    const { hideName, showTooltip } = this.props;

    if (hideName && showTooltip) {
      return (
        <div className="photo__tooltip">
          <Tooltip title={this.getTooltipTipMessage(user)}>
            {this.renderUserPhoto(user)}
          </Tooltip>
        </div>
      );
    } else {
      return this.renderUserPhoto(user);
    }
  };

  renderProfileImage = () => {
    const { user } = this.props;

    if (user) {
      return this.renderUserProfileImage(user);
    } else {
      return this.renderMissingProfileImage(user);
    }
  };

  render() {
    const { hideImage, hideName, textType } = this.props;

    return (
      <div className="Username" data-testid="Username">
        {!hideImage && (
          <div className="Username__photo">{this.renderProfileImage()}</div>
        )}
        {!hideName && <Text className="Username__name" type={textType}>{this.getUsernameText()}</Text>}
      </div>
    );
  }
}

export default Username;
