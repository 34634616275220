import { createInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import locales from "./index";
import { default as commonLocales } from "../../../../common/translations/index";

const i18n = createInstance({
  fallbackLng: "en",
});

i18n.use(initReactI18next).init({
  interpolation: {
    defaultVariables: {
      creator: "-- deleted user --",
    },
  },
  resources: {
    en: {
      default: locales.en,
      common: commonLocales.en,
    },
    es: {
      default: locales.es,
      common: commonLocales.es,
    },
    fr: {
      default: locales.fr,
      common: commonLocales.fr,
    },
    "pt-BR": {
      default: locales.ptBR,
      common: commonLocales.ptBR,
    },
  },
});

export default i18n;
