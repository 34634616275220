import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_USERS_ENDPOINT = (id) =>
  endpoint(
    `app/v1.0/campaigns/${id}/users.json`
  );
const CAMPAIGN_USERS_CREATE_ENDPOINT = (id) =>
  endpoint(`app/v1.0/campaigns/${id}/users.json`);

const CAMPAIGN_USER_ENDPOINT = (id, userId) =>
  endpoint(`app/v1.0/campaigns/${id}/users/${userId}.json`);

export async function createCampaignUser(campaignId, payload) {
  const response = await axiosService.axiosPost(
    CAMPAIGN_USERS_CREATE_ENDPOINT(campaignId),
    payload
  );

  return response.data.data;
}

export async function getCampaignUsers(campaignId, params = {}) {
  try {
    const response = await axiosService.axiosGet(
      CAMPAIGN_USERS_ENDPOINT(campaignId),
      params
    );

    if (response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (err) {
    return [];
  }
}

export async function updateCampaignUser(campaignId, userId, payload) {
  try {
    const response = await axiosService.axiosPut(
      CAMPAIGN_USER_ENDPOINT(campaignId, userId),
      payload
    );

    return response.data.data;
  } catch (err) {
    return {};
  }
}

export default {
  createCampaignUser,
  getCampaignUsers,
};
