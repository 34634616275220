import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { rootStorePropTypes } from "../../../../proptypes/stores";
import { LeadStats } from "./LeadStats";

const DashboardStats = inject("rootStore")(observer((props) => {
  const { rootStore } = props;
  const { filtersStore, funnelStore, listLeadsStore, mapStore, viewStore } = rootStore;
  const { view } = viewStore;
  const { filterCount } = filtersStore;
  const [showLeadStats, setShowLeadStats] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowLeadStats(location.pathname.includes("/dashboard"));
  }, [location]);

  const getLeadStats = () => {
    if (view.id === "map") {
      return mapStore.leadStats;
    } else if (view.id === "funnel") {
      return funnelStore.leadStats;
    } else {
      return listLeadsStore.leadStats;
    }
  };

  const whenNoFilters = (filterCount === 0) && view.id !== "map";
  return (
    showLeadStats && <LeadStats {...getLeadStats()} showOnlyTotal={whenNoFilters} />
  );
}));

DashboardStats.propTypes = {
  rootStore: rootStorePropTypes,
  t: PropTypes.func,
};

export { DashboardStats };

export default DashboardStats;
