import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_VARIABLES_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/variables`);

const CAMPAIGN_VARIABLE_ENDPOINT = (campaignId, variableId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/variables/${variableId}`);

export async function getVariables(campaignId) {
  const response = await axiosService.axiosGet(
    CAMPAIGN_VARIABLES_ENDPOINT(campaignId)
  );

  return response.data.data;
}

export async function putVariable(campaignId, variableId, payload) {
  const response = await axiosService.axiosPut(
    CAMPAIGN_VARIABLE_ENDPOINT(campaignId, variableId),
    payload
  );

  return response.data;
}

export async function postVariable(campaignId, payload) {
  const response = await axiosService.axiosPost(
    CAMPAIGN_VARIABLES_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}

export async function deleteVariable(campaignId, variableId) {
  const response = await axiosService.axiosDelete(
    CAMPAIGN_VARIABLE_ENDPOINT(campaignId, variableId)
  );

  return response.data;
}

export default {
  getVariables,
  putVariable,
  postVariable,
};
